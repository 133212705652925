<!-- 表头选择标题 -->
<template>
  <div class="row">
    <div class="pull-left dragBox">
      <!-- 可选字段 -->
      <span class="font_style">{{ $t('label.select.field') }}</span>
      <div class="shuttle">
        <div  class="shuttle-search">
          <i
            class="iconfont icon-search-fill icon_s"
            style="margin-left: 10px"
          ></i>
          <!-- 搜索字段 -->
          <el-input
            :placeholder="$t('label.searchfield')"
            class="left_inp"
            v-model="searchVal"
            @input="querySearchAsync"
          ></el-input>
        </div>
        <draggable
          class="list-group ms-column itemBox shu_left"
          v-model="originFields"
          group="tasks"
          animation="300"
          v-loading="loadingSeleccted"
          @end="onEndLeft"
        >
          <span
            class="dragItem"
            v-for="(element, index) in originFields"
            :key="index"
            @click="allLeftSelect(element, index)"
            :class="[element.isselect ? 'Selectes' : '']"
            :id="element.id"
            >{{ element.labelName }}</span
          >
        </draggable>
      </div>
    </div>
    <el-button-group class="button-box">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('vue_label_normal_cancel')"
        placement="top-start"
      >
        <el-button
          type="primary"
          class="left button-box-left"
          @click="allRight.length > 0 ? allRightClick() : ''"
        >
        <svg class="icon leftBlack" aria-hidden="true" style=""><use href="#icon-leftBlack-copy"></use></svg>
        </el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('vue_label_normal_select')"
        placement="top-start"
      >
        <el-button
          type="primary"
          @click="allLeft.length > 0 ? allLeftClick() : ''"
          style="margin-right: 0px"
          class="rights button-box-right"
        >
        <svg class="icon rightBlack" aria-hidden="true" style=""><use href="#icon-rightBlack-copy"></use></svg>
        </el-button>
      </el-tooltip>
    </el-button-group>

    <div class="pull-left dragBox" id="background">
      <!-- 已选字段 -->
      <span class="font_style">{{ $t('label.chosen.field') }}</span>
      <draggable
        class="list-group ms-column itemBox shu_right"
        v-model="viewSelectedFieldList"
        group="tasks"
        sort="true"
        animation="300"
        v-loading="loadingSeleccted"
        @end="onEndRight"
      >
        <span
          class="dragItem pad_s"
          v-for="(element, index) in viewSelectedFieldList"
          :key="index"
          @click="allRightSelect(element, index)"
          :class="[element.isselect ? 'Selectes' : '']"
          :id="element.id"
          >{{ element.labelName }}</span
        >
      </draggable>
    </div>

    <ul class="ulButton" ref="ulButton">
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_top')"
          placement="top-start"
          :open-delay="1000"
        >
          <svg class="icon topBlack" aria-hidden="true" @click="top"><use href="#icon-topBlack-copy"></use></svg>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_moveup')"
          placement="top-start"
          :open-delay="1000"
        >
          <svg class="icon left_icon leftBlack" aria-hidden="true" @click="shiftUp"><use href="#icon-leftBlack-copy"></use></svg>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_movedown')"
          placement="top-start"
          :open-delay="1000"
        >
           <svg class="icon right_icon rightBlack" aria-hidden="true" @click="shiftDown"><use href="#icon-rightBlack-copy"></use></svg>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_bottom')"
          placement="top-start"
          :open-delay="1000"
        >
          <svg class="icon bottomBlack" aria-hidden="true" @click="tail"><use href="#icon-bottomBlack-copy"></use></svg>
        </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'app',
  components: {
    draggable,
  },
  props: [
    'viewUnselectedFieldList',
    'viewSelectedFieldArr',
    'unselectedFieldList',
    'selectedFieldList',
  ],
  // viewUnselectedFieldList 可选字段
  // viewSelectedFieldArr 已选字段
  data() {
    return {
      searchVal: '',
      newTask: '',
      originFields: [],
      ids: '',
      viewSelectedFieldList: [],
      copyList: [],
      nArr: [],
      shiftKey: null, //监听是否按下shift
      origin: -1, //记录起点
      allLeft: [], //左边穿梭框存放shift选中数据
      allRight: [], //右边穿梭框存放shift选中数据
      siteObj: {},
      index: null,
      moveArr: [],
      timeout: null,
      searchArr: [],
      loading: false,
      loadingSeleccted: false,
    }
  },
  created() {},
  mounted() {
    // 弹框打开的时候清空装下标的数组
    this.$bus.$on('clearIndexArr',this.clearIndexArrCallback)
    this.initPage()
    //监听是否按下shift事件，如果按下shift设置shiftKey为true
    window.addEventListener('keydown', this.keydownFn)
    //监听是否松开shift事件，如果按下shift设置shiftKey为false
    window.addEventListener('keyup', this.keyupFn)
  },
  beforeDestroy() {
    this.$bus.$off('clearIndexArr',this.clearIndexArrCallback)
    window.removeEventListener("keydown", this.keydownFn);
    window.removeEventListener("keyup", this.keyupFn);
  },
  methods: {
    clearIndexArrCallback() {
      this.moveArr = []
      this.allRight = []
    },
    //监听是否按下shift事件，如果按下shift设置shiftKey为true
    keydownFn(e) {
      if (e.keyCode === 16 && e.shiftKey) {
        this.shiftKey = true
      }
    },
    //监听是否松开shift事件，如果按下shift设置shiftKey为false
    keyupFn() {
      this.shiftKey = false
    },
    initPage() {
      if (this.viewUnselectedFieldList !== undefined) {
      if (this.viewUnselectedFieldList.length !== 0) {
        this.originFields = this.viewUnselectedFieldList
        this.searchArr = this.viewUnselectedFieldList
      }
    }
    this.viewSelectedFieldList = this.viewSelectedFieldArr
    },
    array_diff(a, b) {
      // 查询数据去重
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i].id) {
            a.splice(j, 1)
            j = j - 1
          }
        }
      }
      return a
    },
    checkSelIsEmpty(nval) {
      // 校验已选字段是否为空
      this.viewSelectedFieldList = nval
      let selecteArr = []
      if (this.viewSelectedFieldList !== undefined) {
        this.viewSelectedFieldList.forEach((val, index) => {
          selecteArr.push(val.id)
        })
        this.$emit('selFun', selecteArr.join(','))
      }
    },
    //左边穿梭框点击事件
    allLeftSelect(item, index) {
      const data = this.originFields //左边穿梭框数据
      const origin = this.origin //起点标识
      const enIdx = index //选中数据的index
      if (this.shiftKey) {
        //this.shiftKey为true时
        const sum = Math.abs(origin - enIdx) + 1 //记录终点
        const min = Math.min(origin, enIdx) //记录起点
        let i = 0

        while (i < sum) {
          const index = min + i
          this.originFields[index].isselect = true //接口返回数据时添加isselect字段默认为false，如果选中则为true用以添加样式
          i++
          this.allLeft.push(this.originFields[index]) //将shift选中的数据存放到数组中
        }
        this.unique(this.allLeft) //将左边存放shift数据的数组进行去重
        this.$forceUpdate()
      } else {
        //this.shiftKey为false时
        this.origin = index
        if (item.isselect) {
          //判断是否当前选中的字段是否为已选中状态
          item.isselect = false //如果当前选中的字段是true则改为false
          this.allLeft.forEach((items, index) => {
            //将当前选中的字段是true改为false时删除this.allLeft中的这条数据
            if (items.id === item.id) {
              this.allLeft.splice(index, 1)
            }
          })
        } else {
          this.allLeft.push(item) //如果不为true则添加到this.allLeft中
          item.isselect = true
        }
        this.$forceUpdate()
      }
    },
    allLeftClick() {
      //点击向右按钮
      this.allLeft.forEach((item, index) => {
        item.isselect = false //将选中状态改为false
        if (this.viewSelectedFieldList.length !== 15) {
          this.viewSelectedFieldList.push(item) //点击向右的按钮将状态为选中的数据存放到右边的穿梭框中
          this.originFields.forEach((items, index) => {
            if (item.id === items.id) {
              this.originFields.splice(index, 1) //删除左边穿梭框中被选中的数据
            }
          })
        } else {
          this.$message.error(
            this.$i18n.t('vue_label_commonobjects_view_to_display_fields')
          )
          this.allLeft = []
        }
      })
      this.$nextTick(() => {
        this.unique(this.viewSelectedFieldList) //对右边穿梭框的数据进行去重
        this.allLeft = []
      })
    },
    allRightSelect(item, index) {
      let xiabiao = index
      // if(this.siteObj!={}){
      //   this.siteObj.isselect = false;//上一个点击的改为不选中样式
      // }
      // item.isselect = true;
      // this.$forceUpdate()

      this.siteObj = item
      this.index = xiabiao

      // this.moveArr.splice(0,1,index);//数组中只保存当前点击的元素下标
      // this.allRight.splice(0,1,this.viewSelectedFieldList[index]);

      const data = this.viewSelectedFieldList //右边穿梭框数据
      const origin = this.origin //起点标识
      const enIdx = xiabiao //选中数据的index

      if (this.shiftKey) {
        //this.shiftKey为true时
        const sum = Math.abs(origin - enIdx) + 1 //记录重点
        const min = Math.min(origin, enIdx) //记录起点
        let i = 0
        while (i < sum) {
          const index = min + i
          this.viewSelectedFieldList[index].isselect = true //接口返回数据时添加isselect字段默认为false，如果选中则为true用以添加样式
          i++
          this.allRight.push(this.viewSelectedFieldList[index]) //将shift选中的数据存放到数组中
          this.moveArr.push(index) //下标装进数组中
        }
        this.$forceUpdate()
      } else {
        //this.shiftKey为false时
        this.origin = xiabiao
        if (item.isselect) {
          item.isselect = false //如果当前选中的字段是true则改为false
          this.allRight.forEach((items, index) => {
            //将当前选中的字段是true改为false时删除this.allLeft中的这条数据
            if (items.id === item.id) {
              this.allRight.splice(index, 1)
              // this.moveArr.splice(xiabiao, 1)
            }
          })
          // 获取this.moveArr 中xiabiao的索引，不是选中状态时将该下标移除
          const indexClick = this.moveArr.indexOf(xiabiao)
          this.moveArr.splice(indexClick, 1) 
        } else {
          item.isselect = true
          this.allRight.push(item) //如果不为true则添加到this.allRight
          this.moveArr.push(xiabiao)
        }
        this.$forceUpdate()
      }
      this.unique(this.allRight)
      this.unique(this.moveArr) //去除重复的下标
    },
    allRightClick() {
      this.unique(this.allRight) //将右边存放shift数据的数组进行去重
      this.allRight.forEach((item, index) => {
        item.isselect = false //将选中状态改为false
        this.originFields.unshift(item) //点击向左的按钮将状态为选中的数据存放到右边的穿梭框中
        this.viewSelectedFieldList.forEach((items, index) => {
          if (item.id === items.id) {
            this.viewSelectedFieldList.splice(index, 1) //删除右边穿梭框中被选中的数据
          }
        })
      })
      this.$nextTick(() => {
        this.unique(this.originFields) //对左边穿梭框的数据进行去重
        this.allRight = []
        this.moveArr = []
      })
    },
    //监听左边穿梭框拖拽结束事件
    onEndLeft(e) {
      if (this.viewSelectedFieldList.length >= 16) {
        this.viewSelectedFieldList.forEach((item, index) => {
          if (item.id === e.clone.id) {
            this.$message.error(
              this.$i18n.t('vue_label_commonobjects_view_to_display_fields')
            )
            this.originFields.unshift(item)
            this.viewSelectedFieldList.splice(index, 1)
          }
        })
      } else {
        //拖拽结束将拖拽的记录从左边选中的数组中删除
        this.allLeft.forEach((item, index) => {
          if (item.id === e.clone.id) {
            this.allLeft.splice(index, 1)
          }
        })
      }
      // 拖拽事件后重新处理moveArr数组
      this.moveArr = [];
      this.viewSelectedFieldList.forEach((item, index) => {
        if (item.isselect) {
          this.moveArr.push(index)
        }
      })
    },
    //监听右边穿梭框拖拽结束事件
    onEndRight(e) {
      //拖拽结束将拖拽的记录从右边选中的数组中删除
      this.allRight.forEach((item, index) => {
        if (item.id === e.clone.id) {
          this.allRight.splice(index, 1)
        }
      })
      // 拖拽事件后重新处理moveArr数组
      this.moveArr = [];
      this.viewSelectedFieldList.forEach((item, index) => {
        if (item.isselect) {
          this.moveArr.push(index)
        }
      })
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    curGroupLists() {
      let selecteArr = []
      this.viewSelectedFieldList &&
        this.viewSelectedFieldList.forEach((val, index) => {
          selecteArr.push(val.id)
        })
      this.$emit('memberFun', selecteArr.join(','))
    },
    //置顶
    top() {
      let indexArr = this.moveArr //同理下移
      indexArr.sort(function(x, y) {
        return x - y
      })
      indexArr.forEach((item, index) => {
        if (item != 0) {
          let arr = this.viewSelectedFieldList
          arr.splice(index, 0, arr.splice(item, 1)[0])
          this.viewSelectedFieldList = arr
          indexArr.splice(index, 1, index) //数组中更新最新的下标
        }
      })
      this.moveArr = indexArr //同理 下移
      this.$forceUpdate()
    },
    //置尾
    tail() {
      let indexArr = this.moveArr //复制后排序
      indexArr.sort(function(x, y) {
        return y - x
      })
      indexArr.forEach((item, index) => {
        let len = this.viewSelectedFieldList.length - 1
        if (item != len) {
          let arr = this.viewSelectedFieldList
          arr.splice(arr.length - index, 0, arr.splice(item, 1)[0])
          this.viewSelectedFieldList = arr
          indexArr.splice(index, 1, arr.length - index - 1) //数组中更新最新的下标
        }
      })
      this.moveArr = indexArr //移动后重新在给回选中的数组中
      this.$forceUpdate()
    },
    //上移
    shiftUp() {
      let indexArr = this.moveArr //同理下移
      indexArr.sort(function(x, y) {
        return x - y
      })
      indexArr.forEach((item, index) => {
        if (item != 0) {
          let arr = this.viewSelectedFieldList
          arr.splice(item - 1, 0, arr.splice(item, 1)[0])
          this.viewSelectedFieldList = arr
          indexArr.splice(index, 1, item - 1) //数组中更新最新的下标
        }
      })
      this.moveArr = indexArr //同理 下移
      // 如果moveArr的每一项值都相等，则说明没有可以上移的了
      let isAllSame = this.moveArr.every((item) => {
        return item === this.moveArr[0]
      })
      if (isAllSame && this.moveArr.length>1) {
        // 没有可以上移,再次取消右侧选中
        this.viewSelectedFieldList.forEach((item) => {
          item.isselect = false
        });
        this.moveArr = []
      }
      this.$forceUpdate()
    },
    //下移
    shiftDown() {
      let indexArr = this.moveArr //复制后排序
      indexArr.sort(function(x, y) {
        return y - x
      })
      indexArr.forEach((item, index) => {
        let len = this.viewSelectedFieldList.length - 1
        if (item != len) {
          let arr = this.viewSelectedFieldList
          arr.splice(item + 1, 0, arr.splice(item, 1)[0])
          this.viewSelectedFieldList = arr
          indexArr.splice(index, 1, item + 1) //数组中更新最新的下标
        }
      })
      this.moveArr = indexArr //移动后重新在给回选中的数组中
      // 如果moveArr的每一项值都相等，则说明没有可以移动的了
      let isAllSame = this.moveArr.every((item) => {
        return item === this.moveArr[0]
      })
      if (isAllSame && this.moveArr.length>1) {
        // 没有可以上移,再次取消右侧选中
        this.viewSelectedFieldList.forEach((item) => {
          item.isselect = false
        });
        this.moveArr = []
      }
      this.$forceUpdate()
    },
    // 搜索字段
    querySearchAsync(nval) {
      this.originFields = this.searchArr //初始化
      if (nval !== '') {
        let res = this.originFields.filter((item) => {
          return (
            item.label &&
            item.label.toLowerCase().indexOf(nval.toLowerCase()) != -1
          )
        })
        this.originFields = res
      } else {
        this.originFields = this.searchArr
      }
    },
    //点击其他地方清空svg选中效果(此方法已废弃)
    hideBtn(){

    },
  },
  watch: {
    // moveArr(n,o){
    //   
    // },
    // allRight(n,o){
    //   
    // },
    // shiftKey(n,o){
    //   
    // },
    viewSelectedFieldList(nval, oval) {
      this.checkSelIsEmpty(nval)
    },
    viewSelectedFieldArr(nval, oval) {
      this.checkSelIsEmpty(nval)
    },
    unselectedFieldList(val) {
      if (val.length !== 0) {
        this.originFields = val
        this.searchArr = val
      }
    },
    selectedFieldList(val) {
      if (val.length !== 0) {
        this.viewSelectedFieldList = val
      }
    },
    viewUnselectedFieldList(nval, oval) {
      if (nval !== undefined) {
        if (nval.length !== 0) {
          this.originFields = nval
          this.searchArr = nval
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
  width: 480px;
  .dragBox {
    .shuttle {
      width: 176px;
      height: 212px;
      border: 1px solid #ddd;
      position: relative;
      .left_inp {
        width: 80%;
        margin-left: 0;
        ::v-deep input {
          width: 90%;
          border: 0;
          background: none;
          padding: 0;
          outline: none;
          border: none;
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }
  }
}

.shu_right {
  width: 176px !important;
  height: 212px;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: move;
  color: #333333;
  .pad_s {
    padding-left: 10px;
  }
  span {
    padding-left: 20px;
  }
}

.shu_left {
  height: 177px;
  border-top: 1px solid #ddd;
  cursor: move;
  color: #333333;
}
.shu_left span,
.shu_right span {
  font-size: 12px;
  word-break: break-word;
}

.itemBox {
  width: 100%;
  padding: 5px 0;
  overflow: auto;
}

.dragItem {
  width: 100%;
  display: inline-block;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }
}

.btns {
  width: 100%;
  margin: 10px 15px 10px 0;
  text-align: right;
}

.font_style {
  font-size: 14px;
  color: #080707;
  font-weight: bold;
  padding-bottom: 8px;
  display: inline-block;
}
.shuttle-search {
  display: flex;
  align-items: center;
  padding: 2px 0;
}
.icon_s {
  font-size: 20px;
  color: #999;
  // margin-top: 4px;
}

::v-deep .Selectes {
  background: #ddd;
}

.button-box {
  display: flex;
  align-items: center;
  padding: 0 10px;
  .button-box-left, .button-box-right {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding: 9px 2px;
    background-color: #fff;
    padding: 0;
    border: 1px solid #e2e2e2;
  }
  .el-button:hover {
    background-color: #dceeff !important;
    border-color: #dceeff !important;
  }
  .el-button:focus {
    color: #ffffff !important;
    background-color: #006dcc !important;
    border-color: #006dcc !important;
  }
  .el-button--primary {
    border: 1px solid #e2e2e2 !important;
    color: #080707 !important;
    background-color: #ffffff !important;
    border-color: #e2e2e2 !important;
  }
}


::v-deep .ulButton {
  margin-top: 32px;
  margin-left: 10px;
  width: 26px;
  height: 103px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  li {
    width: 26px;
    height: 26px;
    line-height: 26px !important;
    cursor: pointer;
    position: relative;
    svg {
      text-align: center;
      line-height: 26px !important;
      padding: 7px;
    }
    .topBlack,
    .leftBlack,
    .rightBlack,
    .bottomBlack{
      fill:#000;
    }
    .topBlack:active,
    .leftBlack:active,
    .rightBlack:active,
    .bottomBlack:active{
      fill:#fff;
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
    .topBlack:focus,
    .leftBlack:focus,
    .rightBlack:focus,
    .bottomBlack:focus{
      fill:#fff;
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
    .left_icon,.right_icon{
      transform: rotate(90deg);
    }
  }
  li:hover {
    background-color: #dceeff !important;
    border-color: #dceeff !important;
  }
  li:focus{
    background-color: #006dcc !important;
    border-color: #006dcc !important;
  }
}
</style>
